import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store/index'
// import goTo from 'vuetify/es5/services/goto'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta:{
      auth: true,
      title : 'سەرەکی',
    }
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import('../views/users/Users.vue'),
    meta:{
      auth: true,
      title : 'بەکارهێنەرەکان',
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/auth/Login.vue'),
    meta:{
      auth: false,
      title : 'چونەژوورەوە',
    }
  },
  {
    path: '/dolar',
    name: 'Dolar',
    component: () => import('../views/dolar/Edit.vue'),
    meta:{
      auth: false,
      title : 'دۆلاری ڕۆژانە',
    }
  },
  {
    path: '/items',
    name: 'Items',
    component: () => import('../views/items/Items.vue'),
    meta:{
      auth: true,
      title : 'چونەژوورەوە',
    }
  },
  {
    path: '/category',
    name: 'Category',
    component: () => import('../views/category/Category.vue'),
    meta:{
      auth: true,
      title : 'جۆرەکان',
    }
  },
  {
    path: '/company',
    name: 'Company',
    component: () => import('../views/company/Company.vue'),
    meta:{
      auth: true,
      title : 'کۆمپانیاکان',
    }
  },
  {
    path: '/customer',
    name: 'Customer',
    component: () => import('../views/customer/Customer.vue'),
    meta:{
      auth: true,
      title : 'موشتەریەکان',
    }
  },
  {
    path: '/customers/:id',
    name: 'CustomerID',
    component: () => import('../views/customer/CustomerView.vue'),
    meta:{
      auth: true,
      title : 'موشتەریەکان',
    }
  },
  {
    path: '/store',
    name: 'Store',
    component: () => import('../views/store/Store.vue'),
    meta:{
      auth: true,
      title : 'مەخزەنەکان',
    }
  },
  {
    path: '/units',
    name: 'Units',
    component: () => import('../views/units/Units.vue'),
    meta:{
      auth: true,
      title : 'یەکەکان',
    }
  },
  {
    path: '/cities',
    name: 'Cities',
    component: () => import('../views/cities/Cities.vue'),
    meta:{
      auth: true,
      title : 'شارەکان',
    }
  },
  {
    path: '/towns',
    name: 'towns',
    component: () => import('../views/towns/Town.vue'),
    meta:{
      auth: true,
      title : 'ناوچەکان',
    }
  },
  {
    path: '/places',
    name: 'places',
    component: () => import('../views/places/Place.vue'),
    meta:{
      auth: true,
      title : 'گەڕەکەکان',
    }
  },
 
  
 
  {
    path: '/company_invoice',
    name: 'CompanyInvoice',
    component: () => import('../views/company_invoice/Company_invoice.vue'),
    meta:{
      auth: true,
      title : 'پسوڵەی کۆمپانیا',
    }
  },
  {
    path: '/company_invoice_items/:id',
    name: 'CompanyInvoiceItems',
    component: () => import('../views/company_invoice_items/Company_invoice_items.vue'),
    meta:{
      auth: true,
      title : 'کاڵاکانی پسوڵەی کۆمپانیا',
    }
  },
  {
    path: '/company_return',
    name: 'CompanyReturn',
    component: () => import('../views/company_return/Company_return.vue'),
    meta:{
      auth: true,
      title : 'گەڕاوەی کۆمپانیا',
    }
  },
  {
    path: '/company_return_items/:id',
    name: 'CompanyReturnItems',
    component: () => import('../views/company_return_items/Company_return_items.vue'),
    meta:{
      auth: true,
      title : 'کاڵاکانی پسوڵەی کۆمپانیا',
    }
  },
  {
    path: '/wait/',
    name: 'Wait',
    component: () => import('../views/invoice/WaitInvoice.vue'),
    meta:{
      auth: true,
      title : 'وەسڵە نەگەڕاوەکان',
    }
  },
  {
    path: '/money-request/',
    name: 'MoneyRequest',
    component: () => import('../views/Balance_out/Pending.vue'),
    meta:{
      auth: true,
      title : 'داواکاری ڕاکێشانی پارە',
    }
  },
  {
    path: '/balance_out/:id',
    name: 'MoneyRequestView',
    component: () => import('../views/Balance_out/View.vue'),
    meta:{
      auth: true,
      title : 'داواکاری ڕاکێشانی پارە',
    }
  },
  {
    path: '/invoice',
    name: 'Invoice List',
    component: () => import('../views/invoice/InvoiceList.vue'),
    meta:{
      auth: true,
      title : 'لیستی پسوڵەکان',
    }
  },
  {
    path: '/invoice/:id',
    name: 'Mnvoice',
    component: () => import('../views/invoice/InvoiceID.vue'),
    meta:{
      auth: true,
      title : 'پسوڵە',
    }
  },
  {
    path: '/reports',
    name: 'Reports',
    component: () => import('../views/reports/Home.vue'),
    meta:{
      auth: true,
      title : 'ڕاپۆرتەکان',
    }
  },
  {
    path: '*',
    name: 'notfound',
    component: () => import('../views/404.vue'),
    meta:{
      auth: false,
      title : 'نەدۆزرایەوە',
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to , from , next) =>{
  document.title = to.meta.title || 'NOT FOUND';
  if (localStorage.getItem('user')) {
    let user = JSON.parse(localStorage.getItem('user'))
      store.state.auth = {
        user: user,
        isAuth: true
      }
    }
    if(to.matched.some(rec => rec.meta.auth)){
      if(store.state.auth.isAuth){
        next()
      }else{
        next({name:'Login'})
      }
    }else{
      next()
    } 
})

export default router
