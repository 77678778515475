import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    pages : [
      {
        number:1,
        title: 'جۆرەکان',
        icon :'fas fa-list',
        route:'/category',
        color : ''
      },
      {
        number:2,
        title: 'بەرهەمەکان',
        icon :'fas fa-sitemap',
        route:'/items',
        color : ''
      },
      {
        number:3,
        title: 'بەکارهێنەرەکان',
        icon :'fas fa-users',
        route:'/users',
        color : ''
      },
      {
        number:4,
        title: 'کۆمپانیاکان',
        icon :'fas fa-building',
        route:'/company',
        color : ''
      },
      {
        number:5,
        title: 'موشتەریەکان',
        icon :'fas fa-user-check',
        route:'/customer',
        color : ''
      },
      {
        number:6,
        title: 'شارەکان',
        icon :'fas fa-map',
        route:'/cities',
        color : ''
      },
      {
        number:6,
        title: 'ناوچەکان',
        icon :'fas fa-map',
        route:'/towns',
        color : ''
      },
      {
        number:6,
        title: 'گەڕەکەکان',
        icon :'fas fa-map',
        route:'/places',
        color : ''
      },
      {
        number:6,
        title: 'مەخزەن',
        icon :'fas fa-store',
        route:'/store',
        color : ''
      },
      {
        number:7,
        title: 'یەکەکان',
        icon :'fas fa-synagogue',
        route:'/units',
        color : ''
      },
      {
        number:10,
        title: 'پسوڵەی کۆمپانیا',
        icon :'fas fa-file-word',
        route:'/company_invoice',
        color : ''
      },
      {
        number:10,
        title: 'گەڕاوەی کۆمپانیا',
        icon :'fas fa-undo',
        route:'/company_return',
        color : ''
      },
      {
        number:10,
        title: 'پسوڵەکان',
        icon :'fas fa-file-word',
        route:'/invoice',
        color : ''
      },
      {
        number:11,
        title: 'ڕاپۆرتەکان',
        icon :'fas fa-chart-line',
        route:'/reports',
        color : ''
      },
    ],
    token : '',
    auth : {},
    system_settings : {},
    users : [],
    category : [],
    items : [],
    company : [],
    customer : [],
    store : [],
    units : [],
    days : [], 
    expense_types : [],
    expense : [],
    user_store : [],
    cities : [],
    towns:[],
    places:[],
    dollar : 1500,
    locations : [],
    company_invoice : [],
    company_invoice_items : [],
  },
  getters:{
    pages(s){
      return s.pages
    },
    system_settings(s){
      return s.system_settings
    },
    invoice_status_list(){
      return [
        {
          value : 'wait',
          text : 'چاوەڕوانی',
          color:'warning'
        },
        {
          value : 'process',
          text : 'ئامادەکردن',
          color:'primary'
        },
        {
          value : 'done',
          text : 'گەیشتوو',
          color:'success'
        },
      ]
    },
    dollar(s){
      return s.dollar
    },
    auth(s){
      return s.auth
    },
    users(s){
      return s.users
    },
    token(s){
      return s.token
    },
    store(s){
      return s.store
    },
    items(s){
      return s.items
    },
    category(s){
      return s.category
    },
    company(s){
      return s.company
    },
    customer(s){
      return s.customer
    },
    store(s){
      return s.store
    },
    units(s){
      return s.units
    },
    days(s){
      return s.days
    },
    expense_types(s){
      return s.expense_types
    },
    expense(s){
      return s.expense
    },
    user_store(s){
      return s.user_store
    },
    cities(s){
      return s.cities
    },
    places(s){
      return s.places
    },
    towns(s){
      return s.towns
    },
    locations(s){
      return s.locations
    },
    company_invoice(s){
      return s.company_invoice
    },
    company_invoice_items(s) {
      return s.company_invoice_items
    }
  },
})
