<template>
  <div id="app">
    <Navbar v-if="auth.isAuth"/>
    <router-view />
  </div>
</template>
<script>
import Navbar from './components/Navbar'
export default {
  components:{
    Navbar
  },
  computed:{
    auth(){
      return this.$store.getters.auth
    }
  },
  mounted() {
    
  },
}
</script>
<style>
  @import url('./assets/stylesheet.css');
  *{
    font-family: 'Unikurd Jino';
    font-weight: normal;
    font-style: normal;
  }
  .onlyprint{
    display: none;
  }
  @media print {
    .no-print,
    .no-print * {
      display: none !important;
    }
    .report-table{
      page-break-inside: avoid;

    }
    .onlyprint,
    .onlyprint>* {
      height: auto;
      display: block;
      overflow: visible;
    }
    .print-break-page {
      page-break-after: always;
    }
    .page-break {
      display: block;
      page-break-after: always;
      page-break-before: always;
    }

    .my-page {
      height: 100vh;
      position: relative;
      padding-top: 0px;
      margin-top: 0px;
    }
  }
</style>