import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import jQuery from 'jquery'
import axios from 'axios'
import {service} from './service'
Vue.prototype.$service = service

window.$ = window.jQuery = jQuery
import 'bootstrap-v4-rtl'
import 'popper.js'
require('datatables.net');
import '@fortawesome/fontawesome-free/css/all.css'
const api = process.env.NODE_ENV == 'development' ? 'http://localhost:88/drsuhaib/api/' : 'https://api.seenstore.io/'

import 'bootstrap-v4-rtl/dist/css/bootstrap-rtl.css'
import 'bootstrap-v4-rtl/dist/css/bootstrap-rtl.min.css'
import 'datatables.net-bs4/css/dataTables.bootstrap4.css'
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css'
import 'datatables.net-bs4/js/dataTables.bootstrap4.min.js'
import 'datatables.net-bs4/js/dataTables.bootstrap4.js'
import 'bootstrap-select'

axios.defaults.baseURL = api;
Vue.prototype.$api = api+'uploads/';
Vue.prototype.$banner = api+'invoice.jpeg';
axios.defaults.headers.post['Content-Type'] = 'application/json';
Vue.config.productionTip = false

import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

const options = {
  // You can set your default options here
  position: "top-left",
  timeout: 4000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  icon: true,
  rtl: true
};


Vue.use(Toast, options);


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
