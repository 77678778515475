<template>
  <div>
    <nav class="rtl navbar navbar-expand-lg navbar-dark bg-dark">
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01"
        aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
        <router-link class="navbar-brand" to="/">
          <i class="fa fa-home" aria-hidden="true"></i>
        </router-link>
        <router-link class="navbar-brand" to="/wait">
          <i class="fa fa-bell" aria-hidden="true"></i>
        </router-link>
        <router-link class="navbar-brand" to="/money-request">
         <i class="fa fa-dollar-sign" aria-hidden="true"></i>
        </router-link>
        <router-link class="navbar-brand" to="/dolar">
         نرخی دۆلار
        </router-link>
      </div>
        <button class="btn btn-primary" @click="logout">
          چونەدەرەوە {{user.user_name}}
        </button>
    </nav>
  </div>
</template>
<script>
  import axios from 'axios'
  export default {
    computed: {
      user() {
        return this.$store.state.auth.user
      }
    },
    methods: {
      logout(){
        this.$store.state.auth.isAuth = false
        this.$store.state.auth.user = {}
        localStorage.removeItem('user')
        this.$router.push({
          path: '/login'
        })
        setTimeout(() => {
          window.location.reload()
        }, 1000);
      },
      getData() {
        let params = {
          user_id: this.user.user_id,
          store_id: this.user.store_id,
          user_role: this.user.user_role == 'super' ? null : this.user.user_role
        }
        axios.post('init.php', params)
          .then(r => {
            this.$store.state.store = r.data.store
            this.$store.state.system_settings.customer_count = r.data.customer_count
            this.$store.state.items = r.data.items
            this.$store.state.category = r.data.category
            this.$store.state.company = r.data.company
            this.$store.state.users = r.data.users
            this.$store.state.units = r.data.units
            this.$store.state.cities = r.data.cities
            this.$store.state.towns = r.data.towns
            this.$store.state.places = r.data.places
            this.$store.state.dollar = r.data.dolar_price
          })
          .catch(e => {
            console.log(e);
          })
      }
    },
    mounted() {
      this.getData()
    },
  }
</script>