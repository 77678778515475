<template>
  <div>
    <div class="container rtl mt-3">
      <div class="row">
        <div class="col-12">
          <h4>کۆی ژمارەی بەشداربووان {{system_settings.customer_count}} بەشداربوو</h4>
        </div>
      </div>
        <hr>
      <div class="row mx-1">
        <h5>بەشەکان</h5>
      </div>
      <div class="row mx-1">
        <div class="card mx-1 my-1" v-for="(item, index) in pages" :key="index">
          <router-link :to="item.route" class="btn btn-block btn-primary"><span style="font-size:25px">{{item.title}}</span>
            <i :class="item.icon" aria-hidden="true" class="ml-3"></i>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
  export default {
    computed:{
      pages(){
        return this.$store.getters.pages
      },
      system_settings(){
        return this.$store.getters.system_settings
      },
    }
  }
</script>